/***
* Within the Theme.scss we define all theme variables
*
* Common pattern:
*   Define variables like colors in variables
*   Use any of this variables in the theme.scss
*   in the website itself always use theme-variables
*   instead of variables of variables.scss
*
* Guideline:
*   Keept the variables in theme to a minimum!
*
* Goal:
*   If we need to change a color, font or fixed margins/paddings
*   we only need to change it here.
*
***/

@import 'variables';

// Default Font definition
$font: news-gothic-std,
sans-serif;
$font-style: normal;
$font-weight: 400;

// h1
$font-size-h1: 42px;
$font-style-h1: normal;
$font-weight-h1: bold;

$h1-color: $black-modified;

// h2
$h2-color: $black-modified;

// default text
$default-text-color: $pinkish-grey-modified;


// Main Color definitions
$theme-color-1: red;
$theme-color-2: white;
$theme-background-color-1: #fafafa;
$theme-background-color-2: $warm-gray-2;
$theme-background-color-3: #ffffff;
;

// Content
$theme-max-width: $max-width;

// Links
$nav-link: $black-modified;
$nav-active-link: $crulean;

//fonts
$font-family-Roboto: Roboto;
$font-family-SFProText: 'SFProText';
$font-family-SFProText-light: SFProText-light;
$font-family-SFProText-bold: SFProText-Bold;
