/* You can add global styles to this file, and also import other style files */
// TODO: Check old code what to import
/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import 'mixins';
@import 'theme';

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$shop-primary: mat-palette($mat-indigo);
$shop-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$shop-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$shop-theme: mat-light-theme($shop-primary, $shop-accent, $shop-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($shop-theme);

/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "mixins";
@import "theme";

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

* {
  touch-action: manipulation;
  outline: none;
}

body {
  @include default-font();
  background-color: white;
  touch-action: manipulation;
}
.sticky {
  position: fixed;
  padding-top: 30px;
  padding-bottom: 20px;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 20;
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%);
}

.sticky-dark {
  position: fixed;
  padding-top: 60px;
  padding-bottom: 20px;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 20;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,1) 60%, rgba(0,0,0,0) 100%);
}
html {
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
}
div, span, p, button, input {
  touch-action: manipulation;
}
img {
  touch-action: manipulation;
  pointer-events:none;
  -webkit-touch-callout: none;
}
.container-fluid {
  .row {
    .row {
      max-width: 1350px;
    }
  }
}

@font-face {
  font-family: 'SFProText';
  src: url('/assets/fonts/SFProText-Regular/SF Pro Text Regular.ttf') format('ttf'),
    url('/assets/fonts/SFProText-Regular/SF Pro Text Regular.woff') format('woff'),
    url('/assets/fonts/SFProText-Regular/SF Pro Text Regular.woff2') format('woff2'),
    url('/assets/fonts/SFProText-Regular/SF Pro Text Regular.svg') format('svg'),
    url('/assets/fonts/SFProText-Regular/SF Pro Text Regular.eot') format('eot');
}

@font-face {
  font-family: 'SFProText-light';
  src: url('/assets/fonts/SFProText-Light/SF Pro Text Light/SF Pro Text Light.ttf') format('ttf'),
    url('/assets/fonts/SFProText-Light/SF Pro Text Light/SF Pro Text Light.woff2') format('woff2'),
    url('/assets/fonts/SFProText-Light/SF Pro Text Light/SF Pro Text Light.svg') format('svg'),
    url('/assets/fonts/SFProText-Light/SF Pro Text Light/SF Pro Text Light.eot') format('eot');
}

@font-face {
  font-family: 'SFProText-Bold';
  src: url('/assets/fonts/SFProText-Bold/SF Pro Text Bold/SF Pro Text Bold.ttf') format('ttf'),
    url('/assets/fonts/SFProText-Bold/SF Pro Text Bold/SF Pro Text Bold.woff2') format('woff2'),
    url('/assets/fonts/SFProText-Bold/SF Pro Text Bold/SF Pro Text Bold.svg') format('svg'),
    url('/assets/fonts/SFProText-Bold/SF Pro Text Bold/SF Pro Text Bold.eot') format('eot');
}
